import { Outlet } from 'react-router-dom';

import ToastAlert from 'components/ui/toast/toastAlert';

function PublicLayout() {
    return (
        <div data-testid="Layout" className="h-screen overflow-hidden  mb-2">
            <div className="outlet flex flex-col h-[calc(100vh-64px)] overflow-auto">
                <ToastAlert />
                <Outlet />
            </div>
        </div>
    );
}

export default PublicLayout;
