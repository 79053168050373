import { RouteObject } from 'react-router-dom';

import { PATHS } from 'constants/routes.constant';

export const loginRoutes: RouteObject[] = [
    {
        path: PATHS.LOGIN,
        async lazy() {
            const Login = await import('./login');
            return {
                Component: Login.default
            };
        }
    }
];
