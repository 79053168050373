import { Subject, Subscription } from 'rxjs';

const subject = new Subject<IEnvConfig>();

export interface IEnvConfig {
    apiBaseUrl: string;
    appEnv: string;
    buildVersion: string;
}

const initialState: IEnvConfig = {
    apiBaseUrl: '',
    appEnv: '',
    buildVersion: ''
};

let state = initialState;

const envConfigStore = {
    // Initialize the store with the current state
    init() {
        state = { ...state };
        subject.next(state);
    },

    // Update environment config values
    updateEnvConfig(config: IEnvConfig) {
        state = {
            ...state,
            ...config
        };
        subject.next(state);
    },

    // Subscribe to changes in the environment config values
    subscribe(setState: (config: IEnvConfig) => void): Subscription {
        return subject.subscribe(setState);
    },

    // Get the current environment config values
    getEnvConfig() {
        return state;
    },

    // Clear environment config values
    clearEnvConfig() {
        state = initialState;
        subject.next(state);
    },

    // Return the initial state
    initialState
};

export default envConfigStore;
