import { RefObject } from 'react';

import { DataValue, RequestBody } from 'components/ui/dataTable/types/dataTable.types';
import { getHeaderMenu } from 'components/ui/layout/headerMenu/headerMenu.constant';
import { LogLevel } from 'constants/site.constant';
import { getRedirectUrlFromQuery } from 'shared/lib/urlLib';
import AuthStore from 'store/authStore/authStore';
import envConfigStore from 'store/envConfigStore/envConfigStore';
import formStatusStore from 'store/formIoStore/formIoStore';
import NotificationStore, { INotificationModel } from 'store/notificationStore/notificationStore';

export type PlainObject = Record<string, any>;

/**
 * Debounce function to delay the execution of a given function.
 *
 * @template T - The type of the function to debounce.
 * @param {T} func - The function to debounce.
 * @param {number} delay - The delay in milliseconds for the debounce.
 * @returns {(...args: Parameters<T>) => void} - The debounced function.
 */
export const useDebouncedWaitCallback = <T extends (...args: any[]) => void>(
    func: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timer: NodeJS.Timeout;
    return (...args: Parameters<T>): void => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

export const formatDate = (dateString: string): string => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (Number.isNaN(date.getTime())) {
        return '-';
    }

    // Format the date string
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Format to "mm/dd/yyyy hh:mm:ss"
    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
};

interface Data {
    [key: string]: string;
}

export const isDataEmpty = <T extends Data>(data: T): boolean => {
    return Object.values(data).every((value) => value === '');
};

export const toggleLoadingIndicator = (showLoader: boolean) => {
    const body = document.body;
    if (showLoader) {
        body.classList.add('api-loader');
    } else {
        body.classList.remove('api-loader');
    }
};

export const isDataDifferent = <T extends Data>(before: T, after: T): boolean => {
    // Get the keys of the objects
    const keys = Object.keys(before);

    // Check if the values for any key are different
    for (const key of keys) {
        if (before[key] !== after[key]) {
            return true;
        }
    }

    // If all values are the same, return false
    return false;
};

export const formatDateToApi = (date: Date | null = null) => {
    const currentDate = date ? new Date(date) : new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because month is zero-indexed
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

/**
 * Show toast Message
 * @param message
 */
export const showToastMessage = (toastData: INotificationModel) => {
    const { type, message } = toastData;
    NotificationStore.showAlert({ type, message });
};

export const forceInputField = (f: RefObject<HTMLInputElement>) => {
    if (f.current) {
        f.current.focus();
    }
};

export const checkIsValidEmail = (email: string) => {
    const emailRegex = /^[\w+.-]+@[\d.A-Za-z-]+\.[A-Za-z]{2,}$/;
    const matchResult = emailRegex.exec(String(email).toLowerCase());
    return !!matchResult;
};

// Helper function to build the sorting query
export const buildSortQuery = (
    sorting: { propertyName: string; value: string } | undefined
): string | null => {
    if (!sorting?.propertyName) return null;

    const sortDirection = sorting.value === 'desc' ? '-' : '';
    return `sorts=${sortDirection}${sorting.propertyName}`;
};

// Helper function to build the filtering query
export const buildFilterQuery = (
    searchModels: Array<{ propertyName: string; value: string | string[] | Date[] }> | undefined
): string[] => {
    const filterQueries: string[] = [];
    if (!searchModels?.length) return filterQueries;

    for (const filter of searchModels) {
        if (filter.propertyName && filter.value) {
            const filterValue = Array.isArray(filter.value)
                ? filter.value
                      .map((val) => (val instanceof Date ? val.toISOString() : String(val)))
                      .join(',')
                : String(filter.value);

            filterQueries.push(
                `filters=${filter.propertyName}@=${encodeURIComponent(filterValue)}`
            );
        }
    }
    return filterQueries;
};

// Helper function to build the pagination query
export const buildPaginationQuery = (
    pageNo: number | undefined,
    pageCount: number | undefined
): string[] => {
    if (!pageNo || !pageCount) return [];
    return [`page=${pageNo}`, `pagesize=${pageCount}`];
};

// Utility to build query parameters from request body
export const buildQueryParams = (requestBody?: RequestBody): string[] => {
    const queryParams: string[] = [];

    // Build sort query
    const sortQuery = buildSortQuery(requestBody?.sorting);
    if (sortQuery) queryParams.push(sortQuery);

    // Build filter queries
    const filterQueries = buildFilterQuery(requestBody?.searchModels);
    queryParams.push(...filterQueries);

    // Build pagination queries
    const paginationQueries = buildPaginationQuery(requestBody?.pageNo, requestBody?.pageCount);
    queryParams.push(...paginationQueries);

    return queryParams;
};

/**
 * Extracts a parameter from a given pathname that starts with the specified prefix.
 *
 * @param {string} pathname - The URL pathname from which to extract the parameter.
 * @param {string} prefix - The prefix that the parameter starts with (e.g., '@id', '@name').
 * @returns {string | null} - The extracted parameter value without the prefix, or null if not found.
 *
 * @example
 * const customerId = extractPathParam('/customer/@id1234', '@id'); // '1234'
 * const customerName = extractPathParam('/customer/@nameJohnDoe', '@name'); // 'UHC'
 */
export const extractPathParam = (pathname: string, prefix: string): string | null => {
    const part = pathname.split('/').find((p) => p.startsWith(prefix));
    return part ? part.replace(prefix, '') : null;
};

/**
 * Resets the form by triggering the reset button and updating the form status.
 *
 * @param {string} id - The current sidebar of the form.
 * @returns {Promise<void>} A promise that resolves after form reset.
 */
export const handleReset = async (id: string): Promise<void> => {
    (document.querySelector(`#reset-${id}`) as HTMLButtonElement)?.click();

    await new Promise((r) => setTimeout(r, 100));
    formStatusStore.updateFormData(null, false);
};

/**
 * Validates the form is valid.
 *
 * @param {string} id - The current sidebar of the form.
 * @returns {Promise<boolean>} True if the form is valid, false otherwise.
 */
export const isFormValid = async (id: string): Promise<boolean> => {
    (document.querySelector(`#submit-${id}`) as HTMLButtonElement)?.click();
    await new Promise((r) => setTimeout(r, 100));
    const updatedFormStatusStore = formStatusStore.getFormData();
    return updatedFormStatusStore.hasError ?? true;
};

export const isObject = (value: unknown): value is PlainObject => {
    return typeof value === 'object' && value !== null && !Array.isArray(value);
};

export const hasOwn = (obj: PlainObject, key: string): boolean => {
    // eslint-disable-next-line no-prototype-builtins
    return obj.hasOwnProperty(key);
};

export const sortObject = (obj: PlainObject): PlainObject => {
    if (obj === null || obj === undefined) {
        return {};
    }

    const sortedObj: PlainObject = {};
    const sortedKeys: string[] = Object.keys(obj).sort((a, b) => a.localeCompare(b));

    for (const key of sortedKeys) {
        const value: unknown = obj[key]; // Use `unknown` for better type safety
        if (isObject(value)) {
            sortedObj[key] = sortObject(value); // Cast `value` to `PlainObject`
        } else if (Array.isArray(value)) {
            sortedObj[key] = [...(value as unknown[])] // Ensure value is an array
                .sort((a, b) => {
                    const strA = isObject(a) ? JSON.stringify(sortObject(a)) : String(a);
                    const strB = isObject(b) ? JSON.stringify(sortObject(b)) : String(b);
                    return strA.localeCompare(strB);
                });
        } else {
            sortedObj[key] = value;
        }
    }

    return sortedObj;
};

export const compareValues = (val1: any, val2: any): boolean => {
    if (isObject(val1) && isObject(val2)) {
        return deepEqualValuesOnly(val1, val2);
    } else if (Array.isArray(val1) && Array.isArray(val2)) {
        return compareArrays(val1, val2);
    } else {
        return String(val1) === String(val2);
    }
};

export const compareArrays = (arr1: any[], arr2: any[]): boolean => {
    if (arr1.length !== arr2.length) {
        return false;
    }
    for (const [index, value] of arr1.entries()) {
        if (!compareValues(value, arr2[index])) {
            return false;
        }
    }
    return true;
};

export const trimStringValues = (obj: Record<string, any>): Record<string, any> => {
    const result: Record<string, any> = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const value: unknown = obj[key];
            if (typeof value === 'string') {
                result[key] = value.trim();
            } else if (Array.isArray(value)) {
                result[key] = value.map((item: unknown) =>
                    typeof item === 'string' ? item.trim() : item
                );
            } else if (typeof value === 'object' && value !== null) {
                result[key] = trimStringValues(value); // Recursively trim nested objects
            } else {
                result[key] = value; // Keep other types as is
            }
        }
    }
    return result;
};

export const deepEqualValuesOnly = (obj1: PlainObject, obj2: PlainObject): boolean => {
    const sortedObj1 = sortObject(obj1);
    const sortedObj2 = sortObject(obj2);

    for (const key of Object.keys(sortedObj1)) {
        if (!hasOwn(sortedObj2, key)) {
            return false; // Key missing in obj2
        }
        if (!compareValues(sortedObj1[key], sortedObj2[key])) {
            return false;
        }
    }

    return true;
};

export const getRedirectUrl = (): string => {
    const from = getRedirectUrlFromQuery();
    let redirectURL = from ?? localStorage.getItem('redirectUrl') ?? '/';
    if (redirectURL === '/') {
        redirectURL = getHeaderMenu()?.length ? `/${getHeaderMenu()?.[0]?.path}` : '/';
    }
    return redirectURL;
};

export const hasAnyPermission = (permission: object) => {
    return (Object.values(permission) || []).some((p) => AuthStore.hasPermission(p as string));
};

// Helper function to encode a string to Base64
export const encodeBase64 = (str: string): string => btoa(str);

// Helper function to decode a Base64 string
export const decodeBase64 = (encodedStr: string): string => atob(encodedStr);

export const getNestedValue = (obj: DataValue, fieldName: string): unknown => {
    return fieldName.split('.').reduce<unknown>((acc, part) => {
        if (acc === undefined || acc === null) return;

        // Check if the part contains an array index (e.g., 'fileShareNames[0]')
        const arrayMatch = part.match(/(\w+)\[(\d+)]/);
        if (arrayMatch) {
            const arrayField = arrayMatch[1];
            const index = Number.parseInt(arrayMatch[2], 10);

            const array = (acc as Record<string, unknown>)[arrayField];
            if (Array.isArray(array)) {
                return array[index];
            }
            return;
        }

        // Type assertion for object access
        if (typeof acc === 'object' && acc !== null) {
            return (acc as Record<string, unknown>)[part];
        }
        return;
    }, obj);
};

/**
 * Logs messages to the console based on the environment and log level.
 *
 * @param {unknown[]} messages - An array of messages to log.
 * @param {LogLevel} [level=LogLevel.ERROR] - The level of the log (error, warn, info, verbose, log, clear).
 *
 * - Logs error, warn, info, verbose, or log messages in 'development' or 'test' environments.
 * - Additionally, logs messages if `REACT_APP_API_BASE_URL` contains 'dev'.
 * - In 'production', logging will be disabled for all log levels.
 * - Includes a `CLEAR` log level to clear the console.
 *
 * Example usage:
 * logger(['An error occurred:', error]); // Logs an error message
 * logger(['This is a warning'], LogLevel.WARN); // Logs a warning
 * logger(['This is an info message'], LogLevel.INFO); // Logs an info message
 * logger(['This is a verbose log'], LogLevel.VERBOSE); // Logs verbose/debug information
 * logger([], LogLevel.CLEAR); // Clears the console
 */
export const logger = (messages: unknown[], level: LogLevel = LogLevel.ERROR) => {
    const env = process.env.NODE_ENV; // 'development', 'production', or 'test'

    if (
        ['development', 'test'].includes(env || '') ||
        (
            envConfigStore?.getEnvConfig()?.apiBaseUrl ?? process.env?.REACT_APP_API_BASE_URL
        )?.includes('dev')
    ) {
        switch (level) {
            case LogLevel.ERROR: {
                console.error(...messages);
                return;
            }
            case LogLevel.WARN: {
                console.warn(...messages);
                return;
            }
            case LogLevel.INFO: {
                console.info(...messages);
                return;
            }
            case LogLevel.VERBOSE: {
                console.debug(...messages);
                return;
            }
            case LogLevel.CLEAR: {
                console.clear();
                return;
            }
            default: {
                console.log(...messages);
                return;
            }
        }
    }
};
