import { useTranslation } from 'react-i18next';

import adminMenuIcon from 'assets/svgs/admin_menu_icon.svg';
import workflowSettingIcon from 'assets/svgs/workflow-setting.svg';
import NestedLayout, { Tab } from 'components/ui/layout/nestedLayout/nestedLayout';
import { permissions } from 'constants/permission';
import { PATHS } from 'constants/routes.constant';
import { hasAnyPermission } from 'core/utils/utils';

export default function AdminLayout() {
    const { t } = useTranslation();
    const adminTabs = [
        hasAnyPermission(permissions.ManageFileshare) && {
            menu: t('ADMIN.MANAGE_FILE_SHARE.TITLE'),
            id: 1,
            path: PATHS.ADMIN_MANAGE_FILE_SHARE,
            iconPath: adminMenuIcon
        },
        hasAnyPermission(permissions.ManageHotfolders) && {
            menu: t('ADMIN.MANAGE_HOT_FOLDERS'),
            id: 2,
            path: PATHS.MANAGE_HOT_FOLDERS,
            iconPath: workflowSettingIcon
        },
        hasAnyPermission(permissions.ManageWorkflow) && {
            menu: t('ADMIN.MANAGE_WORKFLOW'),
            id: 3,
            path: PATHS.MANAGE_WORKFLOW,
            iconPath: workflowSettingIcon
        }
    ].filter(Boolean) as Tab[];

    return <NestedLayout tabs={adminTabs}></NestedLayout>;
}
