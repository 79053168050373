export const SidebarPT = () => ({
    root: { className: 'w-96 md:w-[458px] shadow-sidebar' },
    header: { className: 'justify-between' },
    closeIcon: { className: 'w-[18px] h-[18px]' },
    closeButton: { className: 'focus:shadow-none' },
    content: { className: 'px-0 overflow-hidden' }
});

export const DialogPT = () => ({
    root: { className: 'w-96 md:w-[458px] shadow-sidebar' },
    header: { className: 'justify-between' },
    closeIcon: { className: 'w-[18px] h-[18px]' },
    closeButton: {
        className:
            'focus:shadow-none bg-lavenderMist text-slate hover:bg-iceberg hover:text-primary rounded-lg'
    },
    content: { className: 'px-0 overflow-hidden' }
});
