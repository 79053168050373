import { RouteObject } from 'react-router-dom';

import { batchRoutes } from './batch/route';
import BatchLayout from './batchLayout';
import { PATHS } from 'constants/routes.constant';

export const batchLayoutRoutes: RouteObject[] = [
    {
        path: `/${PATHS.CUSTOMER}/:customerId/:customerName/:processId/:processName`,
        Component: BatchLayout,
        children: [...batchRoutes]
    }
];
