import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import NestedSidebar from './sidebar/nestedSidebar';
import { renderTitle } from 'shared/jsx/jsxElement';

export interface Tab {
    menu: string;
    id: number;
    title?: string;
    path: string;
    exactPath?: string;
    iconPath: string;
}

interface NestedLayoutProps {
    tabs: Tab[];
}

export default function NestedLayout({ tabs }: Readonly<NestedLayoutProps>) {
    const navigate = useNavigate();
    const location = useLocation();
    const getCurrentTab = () => {
        return (
            tabs.find((tab) =>
                tab?.exactPath
                    ? tab?.exactPath === location.pathname
                    : location.pathname.includes(tab.path)
            ) ?? ({} as Tab)
        );
    };
    const { menu = '', title = '' } = getCurrentTab();

    // Redirect to the first tab if the current path doesn't match any of the tabs
    useEffect(() => {
        const isIncluded = tabs.some((tab) => location.pathname.includes(tab.path));
        if (!isIncluded) navigate(`${tabs[0].path}`);
    }, [location, tabs, navigate]);

    return (
        <div>
            {renderTitle(menu || title)}

            {/* Layout with Sidebar and Main Content */}
            <div className="h-[calc(100vh-124px)] p-6 pb-2 flex justify-between">
                {/* Sidebar */}
                <NestedSidebar tabs={tabs} /> {/* Pass tabs to Sidebar */}
                {/* Main Content Area */}
                <div className="w-[79%] h-full bg-white shadow-custom rounded-b">
                    <Outlet />
                </div>
            </div>
        </div>
    );
}
