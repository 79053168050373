import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import customerProcessIcon from 'assets/svgs/customer-processes.svg';
import NestedLayout from 'components/ui/layout/nestedLayout/nestedLayout';
import { PATHS } from 'constants/routes.constant';
import { extractPathParam } from 'core/utils/utils';

export default function BatchLayout() {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const params = ['@id', '@name', '@processid', '@processname'].map(
        (param) => extractPathParam(pathname, param) ?? ''
    );

    const [customerId, customerName, processId, processName] = params;
    const basePath = `/${PATHS.CUSTOMER}/@id${customerId}/@name${customerName}/@processid${processId}/@processname${processName}`;

    const batchLayoutTabs = [
        {
            menu: t('CUSTOMER.PROCESSES'),
            id: 1,
            path: '',
            iconPath: customerProcessIcon,
            exactPath: basePath
        }
    ];

    return <NestedLayout tabs={batchLayoutTabs} />;
}
