import { RouteObject } from 'react-router-dom';

import AdminLayout from './admin';
import { fileShareRoutes } from './fileshare/route';
import { hotFolderRoutes } from './hotFolder/route';
import { workFlowRoutes } from './workFlow/route';

export const adminRoutes: RouteObject[] = [
    {
        path: '/admin',
        Component: AdminLayout,
        children: [...fileShareRoutes, ...hotFolderRoutes, ...workFlowRoutes]
    }
];
