export const END_POINT: { [key: string]: { [key: string]: string } } = {
    AUTH: {
        LOGIN: 'auth/login'
    },
    USER: {
        ME: 'user/me'
    },
    CUSTOMER: {
        LIST: '/customer',
        PARENTCUSTOMERLIST: '/Customer/Parents',
        ALL_CUSTOMERS: '/Customer/All'
    },
    PROCESS: {
        PROCESS: '/process',
        PROCESS_WORKFLOW: '/process-workflow'
    },
    BATCH: {
        LIST: '/reports/batchsummary'
    },
    HOT_FOLDER_SETUP: {
        LIST: '/Setting',
        EDIT: '/Setting'
    },
    PII_REDACTION: {
        FOLDER_CREATION: '/File/Create'
    },
    PII_REDACTION_STATUS: {
        LIST: '/Customer/Processes/Status'
    },
    TRANSLATIONBATCH: {
        LIST: '/TranslationBatch',
        FILE_NAMES_LIST: '/TranslationBatch/getFileNames'
    },
    FILE_SHARE: {
        LIST: '/storage-accounts',
        CREATE: '/fileshares',
        EDIT: '/FileSharePath',
        DELETE: '/FileSharePath',
        ALL_FILE_PATHS: '/FileSharePath/All'
    }
};

export const API_VERSION: { [key: string]: string } = {
    v1: 'v1',
    ['V1.0']: 'v1.0'
};
