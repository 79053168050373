import { RouteObject } from 'react-router-dom';

import { PATHS } from 'constants/routes.constant';
import { protectedLoader } from 'features/auth/login/login.provider';

export const workFlowRoutes: RouteObject[] = [
    {
        path: PATHS.WORKFLOWS,
        loader: (args) => protectedLoader(args),
        async lazy() {
            const WorkFlow = await import('./workflow');
            return {
                Component: WorkFlow.default
            };
        }
    }
];
