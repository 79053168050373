import { MenuItem } from './headerMenu';
import { headerMenuPermissions } from 'constants/permission';
import { PATHS } from 'constants/routes.constant';
import AuthStore from 'store/authStore/authStore';

export const getHeaderMenu = (): MenuItem[] => {
    const staticMenuItems: MenuItem[] = [
        {
            menu: 'MENU.CUSTOMERS',
            id: 1,
            permission: [headerMenuPermissions.ManageCustomer],
            path: PATHS.CUSTOMER,
            iconPath: 'assets/svgs/customer_menu_icon.svg'
        },
        {
            menu: 'MENU.USERS',
            id: 2,
            permission: [headerMenuPermissions.ManageUser],
            path: PATHS.USERS,
            iconPath: 'assets/svgs/user_menu_icon.svg'
        },
        {
            menu: 'MENU.TRANSLATION_BATCH_DASHBOARD',
            id: 3,
            permission: [headerMenuPermissions.TranslationBatchTracking],
            path: PATHS.TRANSLATION_BATCH_TRACKING,
            iconPath: 'assets/svgs/tbd_menu_icon.svg'
        },
        {
            menu: 'MENU.ADMIN',
            id: 4,
            permission: [headerMenuPermissions.ManageAdmin],
            path: PATHS.ADMIN,
            iconPath: 'assets/svgs/admin_menu_icon.svg'
        }
    ];

    // Dynamic menu items based on permissions
    return staticMenuItems.filter((menu) => {
        return menu.permission?.find((mp) => {
            if (typeof AuthStore.getUserInfo()?.userInfo?.permissions === 'object') {
                // eslint-disable-next-line no-prototype-builtins
                return AuthStore.getUserInfo().userInfo.permissions.hasOwnProperty(mp);
            }
        });
    });
};
