import { PrimeReactProvider } from 'primereact/api';
import Tailwind from 'primereact/passthrough/tailwind';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { twMerge } from 'tailwind-merge';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { getConfig } from 'core/configs/configHelper';
import i18n from 'core/i18next/i18next';
import { logger } from 'core/utils/utils';
import envConfigStore from 'store/envConfigStore/envConfigStore';

export const rootElement = document.querySelector('#root');
void i18n.changeLanguage('en');

const startApp = async (): Promise<void> => {
    try {
        // Wait for the config to load before rendering the app
        const envConfig = await getConfig();
        envConfigStore.updateEnvConfig(envConfig);

        if (rootElement && Object.values(envConfigStore.getEnvConfig()).length > 0) {
            const root = ReactDOM.createRoot(rootElement);
            root.render(
                <React.StrictMode>
                    <QueryClientProvider
                        client={
                            new QueryClient({
                                defaultOptions: {
                                    queries: {
                                        refetchOnWindowFocus: false,
                                        retry: false,
                                        refetchOnReconnect: true
                                    }
                                }
                            })
                        }>
                        <PrimeReactProvider
                            value={{
                                unstyled: true,
                                pt: Tailwind,
                                ptOptions: {
                                    mergeSections: true,
                                    mergeProps: true,
                                    classNameMergeFunction: twMerge
                                }
                            }}>
                            <App />
                        </PrimeReactProvider>
                    </QueryClientProvider>
                </React.StrictMode>
            );
        }
    } catch (error) {
        logger(['Failed to initialize the application:', error]);
    }
};

// Use `void` operator to explicitly ignore the promise
void startApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
