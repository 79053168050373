import './App.css';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import { router } from 'app/pages/route';
import { logger } from 'core/utils/utils';
import { getMe } from 'features/auth/login/login.api.service';
import { ApiResponseWithoutPagination } from 'shared/types/api';
import AuthStore, { UserDetailsModal } from 'store/authStore/authStore';

function App() {
    const { t } = useTranslation();
    const INITIAL_LOAD = `${t('COMMON.INITIAL_LOAD') || 'Initial Load'}...`;
    const { isFetching } = useQuery<ApiResponseWithoutPagination<UserDetailsModal>>(
        'userInfo',
        getMe,
        {
            enabled: AuthStore.isAuthenticated(),
            onSuccess(response) {
                AuthStore.setUserInfo(response.body);
            },
            onError(err) {
                logger(['Error fetching user info:', err]);
            }
        }
    );

    if (isFetching) {
        return <p>{INITIAL_LOAD}</p>;
    }
    const routerConfig = createBrowserRouter(router);
    return (
        <div>
            <RouterProvider router={routerConfig} fallbackElement={<p>{INITIAL_LOAD}</p>} />
        </div>
    );
}

export default App;
