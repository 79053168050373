import { Link, useLocation } from 'react-router-dom';

import { Tab } from '../nestedLayout';

interface SidebarProps {
    tabs: Tab[];
}

export default function NestedSidebar({ tabs }: Readonly<SidebarProps>) {
    const location = useLocation();

    return (
        <div className="h-full w-[20%] bg-white p-3 pt-4 pb-4 shadow-custom rounded-b overflow-auto">
            {tabs.map((menu) => (
                <div
                    key={`s-div-${menu.id}`}
                    className={`w-full p-2 rounded-md font-medium my-2 hover:bg-pale-blue hover:text-primary ${
                        (menu?.exactPath
                            ? menu?.exactPath === location.pathname
                            : location.pathname.includes(menu.path)) && 'bg-pale-blue text-primary'
                    }`}>
                    <Link to={menu.path} key={`s-${menu.id}`} className="flex">
                        <img src={menu.iconPath} className="mr-3" alt="Navbar Icon" />
                        {menu.menu}
                    </Link>
                </div>
            ))}
        </div>
    );
}
