import { PATHS } from './routes.constant';

export const HOT_FOLDER_TYPES = {
    AZURE_LOCATION: { ID: 1 },
    UNREDACTED_FOLDER: { ID: 2 },
    REDACTED_FOLDER: { ID: 3 },
    TO_BE_REIDENTIFIED: { ID: 4 },
    REIDENTIFIED: { ID: 5 },
    FAILED: { ID: 6 },
    READY_FOR_ACCESSIBILITY: { ID: 8 },
    ACCESSIBILITY_PDF_COMPLETED: { ID: 9 }
};

export const STEP_TYPE_STATUS = {
    REDACTION_STARTED: { ID: 1 },
    REDACTION_COMPLETED: { ID: 2 },
    REDACTION_FAILED: { ID: 3 },
    REIDENTIFICATION_STARTED: { ID: 4 },
    REIDENTIFICATION_COMPLETED: { ID: 5 },
    REIDENTIFICATION_FAILED: { ID: 6 },
    PDF_CONVERSION_STARTED: { ID: 7 },
    PDF_CONVERSION_COMPLETED: { ID: 8 },
    PDF_CONVERSION_FAILED: { ID: 9 }
};

export const STEP_TYPE_ID = {
    FILE_TRANSFER: { ID: 1 },
    FILE_DISTRIBUTION: { ID: 2 },
    HOT_FOLDERS: { ID: 3 }
};

export const STEP_ORDER_ID = {
    REDACTION: { ID: 1, name: 'Redaction' },
    RE_IDENTIFICATION: { ID: 2, name: 'Reidentify' },
    PDF_CONVERSION: { ID: 3, name: 'Pdfconversion' }
};

export const MACRO_ID = {
    REDACTION: 1,
    RE_IDENTIFICATION: 2,
    PDF_CONVERSION: 3,
    API_BASED_REDACTION: 4,
    API_BASED_REIDENTIFICATION: 5,
    API_BASED_PDF_CONVERSION: 6
};

export enum WorkFlowStatus {
    ACTIVE = 1,
    INACTIVE = 2
};

export const PII_REDACTION_STATUS_LIST = {
    tableFields: {
        name: '(name|createdFolderPath)',
        customerName: 'customer.name'
    }
};

export const LOCAL_STORAGE_KEYS = {
    JWT_TOKEN: 'JwtToken'
};

export const BREAD_CRUMBS_DATA = [
    {
        label: 'MENU.ADMIN',
        routeKey: PATHS.ADMIN
    },
    {
        label: 'ADMIN.MANAGE_FILE_SHARE.TITLE',
        routeKey: PATHS.ADMIN_MANAGE_FILE_SHARE
    },
    {
        label: 'ADMIN.MANAGE_HOT_FOLDERS',
        routeKey: PATHS.MANAGE_HOT_FOLDERS
    },
    {
        label: 'ADMIN.MANAGE_WORKFLOW',
        routeKey: PATHS.MANAGE_WORKFLOW
    },
    {
        label: 'CUSTOMER.CUSTOMER',
        routeKey: PATHS.CUSTOMER
    },
    {
        label: 'USERS.TITLE',
        routeKey: PATHS.USERS
    },
    {
        label: 'TRANSLATION_BATCH_TRACKING.TITLE',
        routeKey: PATHS.TRANSLATION_BATCH_TRACKING
    }
];

export enum LogLevel {
    ERROR = 'error',
    WARN = 'warn',
    INFO = 'info',
    VERBOSE = 'verbose',
    LOG = 'log',
    CLEAR = 'clear'
}
