import { RouteObject } from 'react-router-dom';

import { adminRoutes } from './admin/route';
import { loginRoutes } from './auth/login/route';
import { batchLayoutRoutes } from './batchLayout/route';
import { processLayoutRoutes } from './customer/processesLayout/route';
import { customerRoutes } from './customer/route';
import { translationBatchTrackingRoutes } from './translationBatchTracking/route';
import UnauthorizedPage from './unauthorizedPage/unauthorizedPage';
import { userRoutes } from './users/route';
import ErrorPage from 'components/ui/errorPage/errorPage';
import PrivateLayout from 'components/ui/layout/privateLayout';
import PublicLayout from 'components/ui/layout/publicLayout';
import { protectedLoader } from 'features/auth/login/login.provider';

export const router: RouteObject[] = [
    {
        id: 'root',
        path: '/',
        errorElement: <ErrorPage />,
        Component: PrivateLayout,
        loader: protectedLoader,
        children: [
            ...customerRoutes,
            ...processLayoutRoutes,
            ...batchLayoutRoutes,
            ...userRoutes,
            ...translationBatchTrackingRoutes,
            ...adminRoutes
        ]
    },
    {
        id: 'auth',
        path: '/auth',
        errorElement: <ErrorPage />,
        Component: PublicLayout,
        children: [...loginRoutes]
    },
    {
        path: 'access-denied',
        Component: UnauthorizedPage
    }
];
