export const isIgnoredPath = (value: string): boolean => {
    const ignoredPathParam = ['id', 'processid'];
    return ignoredPathParam.some((path) => value.startsWith(`@${path}`));
};

export const decodePathSegment = (value: string): string => {
    switch (true) {
        case value.startsWith('@name'): {
            return decodeURIComponent(value.replace('@name', ''));
        }
        case value.startsWith('@processname'): {
            return decodeURIComponent(value.replace('@processname', ''));
        }
        default: {
            return value; // Return as-is for other segments
        }
    }
};
