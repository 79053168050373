import { LoaderFunctionArgs, redirect } from 'react-router-dom';

import { getHeaderMenu } from 'components/ui/layout/headerMenu/headerMenu.constant';
import AuthStore from 'store/authStore/authStore';

export function protectedLoader(
    { request }: LoaderFunctionArgs,
    requiredPermissionOrPermissions?: string | string[]
) {
    // If the user is not logged in and tries to access `/protected pages`, we redirect
    // them to `/login` with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication

    const from = new URL(request?.url ?? '', window.location.origin).pathname;
    if (!AuthStore.isAuthenticated()) {
        localStorage.setItem('redirectUrl', from);
        return redirect(`/auth/login?from=${encodeURIComponent(from)}`);
    }

    if (requiredPermissionOrPermissions) {
        // Handle both single and multiple permissions
        const hasRequiredPermission = Array.isArray(requiredPermissionOrPermissions)
            ? requiredPermissionOrPermissions.some((permission) =>
                  AuthStore.hasPermission(permission)
              )
            : AuthStore.hasPermission(requiredPermissionOrPermissions);

        if (!hasRequiredPermission) {
            return redirect('/access-denied');
        }
    }

    const fromURL = from.replace('/', '').split('/')[0];
    const menuItem = getHeaderMenu() || [];
    const currentMenu = menuItem.find((menu) => menu.path === fromURL);

    if (fromURL === '' && menuItem.length > 0) {
        return redirect(`/${menuItem?.[0]?.path}`);
    }

    if (!currentMenu) {
        // If the user does not have the required permission, redirect them to a page indicating access denied
        return redirect('/access-denied');
    }

    return null;
}
