import { BreadCrumb } from 'primereact/breadcrumb';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { decodePathSegment, isIgnoredPath } from './breadCrumb.helper';
import { BREAD_CRUMBS_DATA } from 'constants/site.constant';

type BreadcrumbItem = {
    label: string;
    url: string;
};

export default function BreadCrumbs() {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

    useEffect(() => {
        const items = createBreadcrumbItems(location.pathname);
        setBreadcrumbItems(items);
    }, [location, t]);

    const createBreadcrumbItems = (pathname: string): BreadcrumbItem[] => {
        const pathnames = pathname.split('/').filter(Boolean);

        return pathnames
            .map((value, index): BreadcrumbItem | null => {
                if (isIgnoredPath(value)) return null;

                const processedLabel = decodePathSegment(value);
                const routeKey = `/${pathnames.slice(0, index + 1).join('/')}`;
                const breadcrumb = BREAD_CRUMBS_DATA.find((item) => item.routeKey === value);

                return {
                    label: breadcrumb ? t(breadcrumb.label) : processedLabel,
                    url: routeKey
                };
            })
            .filter((item): item is BreadcrumbItem => item !== null);
    };

    const createBreadcrumbModel = (items: BreadcrumbItem[]) => {
        return items.map((item, index) => {
            const isClickable = index < items.length - 1; // True for items before the last one

            return {
                label: item.label,
                template: (
                    <button
                        className={isClickable ? 'cursor-pointer' : 'text-red-500 !cursor-default'}
                        onClick={() => {
                            isClickable && navigate(item.url);
                        }}
                        onKeyDown={(event) => handleKeyDown(event, isClickable, item.url)}>
                        {item.label}
                    </button>
                )
            };
        });
    };

    // Handle home breadcrumb separately
    const home: BreadcrumbItem =
        breadcrumbItems.length > 0 ? breadcrumbItems[0] : { label: 'Home', url: '/' };

    const model = createBreadcrumbModel(breadcrumbItems);

    const handleKeyDown = (
        event: React.KeyboardEvent<HTMLButtonElement>,
        isClickable: boolean,
        url: string
    ) => {
        if (isClickable && (event.key === 'Enter' || event.key === ' ')) {
            event.preventDefault();
            navigate(url);
        }
    };

    return (
        <div>
            {model.length > 0 ? (
                <BreadCrumb
                    model={model}
                    unstyled={true}
                    pt={{
                        root: {
                            className:
                                'text-xs py-2 w-full rounded-none border-0 overflow-hidden pl-5 max-h-[29px] border-b-1.3 border-b-lavenderMist'
                        },
                        action: { className: '!shadow-none text-inherit' },
                        menuitem: { className: 'text-xs leading-none' },
                        label: { className: 'text-xs' },
                        separator: { className: 'mt-0.5 mx-1 opacity-75 first:hidden' }
                    }}
                />
            ) : (
                <div className="py-2 w-full text-xs rounded-none border-x-0 overflow-hidden pl-5 max-h-[29px] bg-white border-b-1.3 border-b-lavenderMist text-secondary">
                    {home.label}
                </div>
            )}
        </div>
    );
}
