import { RouteObject } from 'react-router-dom';

import { permissions } from 'constants/permission';
import { protectedLoader } from 'features/auth/login/login.provider';

export const processRoutes: RouteObject[] = [
    {
        path: '',
        index: true,
        loader: (args) => protectedLoader(args, Object.values(permissions.ManageProcess)),
        async lazy() {
            const Processes = await import('./processes');
            return {
                Component: Processes.default
            };
        }
    }
];
