import { Sidebar, SidebarProps } from 'primereact/sidebar';

import { CustomSidebarProps } from './customSidebar.types';
import { SidebarPT } from 'constants/styleConstants';
import { handleReset, isFormValid } from 'core/utils/utils';
import formStatusStore from 'store/formIoStore/formIoStore';

export default function CustomSidebar({
    id,
    visible,
    title,
    position = 'right',
    onHide,
    onSubmit = () => {},
    createButtonText,
    resetButtonText,
    children,
    showButtonValidation = false,
    isEdit = false,
    pt,
    disableSubmit,
    sidebarCustomStyle,
    customReset,
    renderCustomContent,
    renderCustomHeaderContent,
    skipValidation = false,
    showFooter = true,
    isFormIo = true
}: CustomSidebarProps & SidebarProps): JSX.Element {
    const handleResetButton = async () => {
        if (customReset) customReset();
        else if (isEdit) onHide();
        else {
            await handleReset(id);
        }
    };

    const isDisabled = (validation = false) => {
        return showButtonValidation ? validation : false;
    };

    const handleSubmit = async (sidebarId: string) => {
        if (skipValidation || !(await isFormValid(sidebarId))) {
            onSubmit();
        }
    };

    const renderHeaderContent = () => {
        return <h1 className="text-[20px] font-medium">{title}</h1>;
    };

    const submitDisabled = () => {
        return (
            disableSubmit ?? isDisabled(isEdit ? !formStatusStore.getFormData()?.isChanged : false)
        );
    };

    return (
        <Sidebar
            header={renderCustomHeaderContent ?? renderHeaderContent}
            visible={visible}
            onHide={onHide}
            position={position}
            pt={pt ?? SidebarPT()}>
            <div className="flex flex-col">
                {/* Content */}
                <div
                    data-testid={id}
                    className={sidebarCustomStyle?.childrenStyle ?? 'min-h-[calc(100vh-127px)]'}>
                    {children}
                </div>
                {/* Footer */}
                {showFooter && (
                    <div
                        className={`flex h-[58px] justify-between items-center px-2 pl-[18px] gap-[12px] flex-shrink-0 border-t border-t-mercury`}>
                        <div className="max-h-[37px] overflow-y-auto">
                            {renderCustomContent?.()}
                        </div>
                        <div className="flex items-center gap-[12px] flex-shrink-0">
                            <button
                                className="text-mid-gray text-center text-[14px] font-medium leading-[24px] rounded-[5px] h-[36px] py-1.5 px-4 border border-geyser"
                                onClick={() => {
                                    void handleResetButton();
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        void handleResetButton();
                                    }
                                }}
                                disabled={false}>
                                {resetButtonText}
                            </button>
                            <button
                                data-testid={'submit-button-popup'}
                                className={`text-desert-storm text-center text-[14px] bg-primary font-medium leading-[24px] rounded-[5px] h-[36px] py-1.5 px-4 border border-geyser`}
                                onClick={() => {
                                    if (isFormIo) void handleSubmit(id);
                                    else if (onSubmit) onSubmit();
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                        void handleSubmit(id);
                                    }
                                }}
                                disabled={submitDisabled()}>
                                {createButtonText}
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </Sidebar>
    );
}
