import { RouteObject } from 'react-router-dom';

import { PATHS } from 'constants/routes.constant';

export const userRoutes: RouteObject[] = [
    {
        path: PATHS.USERS,
        async lazy() {
            const Users = await import('./users');
            return {
                Component: Users.default
            };
        }
    }
];
