import { RouteObject } from 'react-router-dom';

import { permissions } from 'constants/permission';
import { PATHS } from 'constants/routes.constant';
import { protectedLoader } from 'features/auth/login/login.provider';

export const hotFolderRoutes: RouteObject[] = [
    {
        path: PATHS.MANAGE_HOT_FOLDERS,
        loader: (args) => protectedLoader(args, Object.values(permissions.ManageHotfolders)),
        async lazy() {
            const HotFolder = await import('./hotFolder');
            return { Component: HotFolder.default };
        }
    }
];
