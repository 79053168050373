import { Outlet } from 'react-router-dom';

import BreadCrumbs from 'components/ui/breadCrumb/breadCrumb';
import HeaderMenu from 'components/ui/layout/headerMenu/headerMenu';
import ToastAlert from 'components/ui/toast/toastAlert';

function PrivateLayout() {
    return (
        <div data-testid="Layout" className="h-screen overflow-hidden">
            <div className="menuBar">
                <HeaderMenu />
                <BreadCrumbs />
            </div>
            <div className="outlet bg-desert-storm flex flex-col h-[calc(100vh-64px)] overflow-auto">
                <ToastAlert />
                <Outlet />
            </div>
        </div>
    );
}

export default PrivateLayout;
