import { RouteObject } from 'react-router-dom';

import { permissions } from 'constants/permission';
import { PATHS } from 'constants/routes.constant';
import { protectedLoader } from 'features/auth/login/login.provider';

export const workFlowRoutes: RouteObject[] = [
    {
        path: PATHS.MANAGE_WORKFLOW,
        loader: (args) => protectedLoader(args, Object.values(permissions.ManageWorkflow)),
        async lazy() {
            const WorkFlow = await import('./workFlow');
            return { Component: WorkFlow.default };
        }
    }
];
