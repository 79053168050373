import { RouteObject } from 'react-router-dom';

import { permissions } from 'constants/permission';
import { protectedLoader } from 'features/auth/login/login.provider';

export const batchRoutes: RouteObject[] = [
    {
        path: '',
        index: true,
        loader: (args) => protectedLoader(args, [permissions.ManageProcess.CAN_GET_PROCESS]),
        async lazy() {
            const Batch = await import('./batch');
            return {
                Component: Batch.default
            };
        }
    }
];
