import { Subject } from 'rxjs';

const subject = new Subject();

export interface IFormStatus {
    data: any;
    isChanged?: boolean;
    hasError?: boolean;
}

const initialState = {
    data: null,
    hasError: true,
    isChanged: false
};

let state = initialState;

const formStatusStore = {
    init() {
        state = { ...state };
        subject.next(state);
    },
    subscribe: (setState: (value: any) => void) => subject.subscribe(setState),
    updateFormData(message: any, isValid: boolean, isChanged?: boolean) {
        state = {
            ...state,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            data: message,
            hasError: !isValid,
            isChanged: !!isChanged
        };
        subject.next(state);
    },
    getFormData() {
        return state as IFormStatus;
    },
    clearFormData() {
        state = initialState;
        subject.next(state);
    },
    initialState
};

export default formStatusStore;
