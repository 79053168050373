import { Toast, ToastProps } from 'primereact/toast';
import { FC, useRef, useState } from 'react';

import errorIcon from 'assets/images/toast-alert-error-icon.svg';
import NotificationStore, {
    INotificationModel,
    ToastType
} from 'store/notificationStore/notificationStore';

const ToastAlert: FC = () => {
    const toastRef = useRef<Toast>(null);
    const position = 'top-right';
    const life = 3000;

    const [errorType, setErrorType] = useState('');

    NotificationStore.subscribe((alert: INotificationModel) => {
        const isInfo = alert.type === ToastType.INFO ? 'bg-primary' : 'bg-error';
        setErrorType(alert.type === ToastType.SUCCESS ? 'bg-success' : isInfo);
        toastRef.current?.clear();
        toastRef.current?.show({ detail: toastMessageContent(alert?.message, alert.type), life });
    });

    const toastMessageContent = (toastMessage: string, type: ToastType) => (
        <div className={`flex gap-3 items-center`}>
            {type === ToastType.SUCCESS && (
                <i className="pi pi-check-circle text-3xl font-normal"></i>
            )}
            {type === ToastType.ERROR && <img alt="icon" src={errorIcon} />}

            <h5 className="font-medium">{toastMessage}</h5>
        </div>
    );

    return (
        <div data-testid="Toast">
            <Toast
                ref={toastRef}
                position={position as ToastProps['position']}
                pt={{
                    root: {
                        className:
                            'opacity-100 w-10/12 sm:w-7/12 md:w-5/12 lg:w-4/12 xl:w-1/5 ToastPopupResponsive'
                    },
                    content: {
                        className: `p-0 rounded-lg text-white ${errorType}`
                    },
                    closeButton: {
                        className: 'mr-2'
                    },
                    summary: {
                        className: 'hidden'
                    },
                    detail: {
                        className: 'mt-0 h-full flex items-center'
                    },
                    text: {
                        className: 'py-4'
                    }
                }}
            />
        </div>
    );
};

export default ToastAlert;
