import { RouteObject } from 'react-router-dom';

import { processRoutes } from './processes/route';
import ProcessesLayout from './processesLayout';
import { workFlowRoutes } from './workflow/route';
import { PATHS } from 'constants/routes.constant';

export const processLayoutRoutes: RouteObject[] = [
    {
        path: `/${PATHS.CUSTOMER}/:customerId/:customerName`,
        Component: ProcessesLayout,
        children: [...processRoutes, ...workFlowRoutes]
    }
];
