import { Link } from 'react-router-dom';

import organizationLogo from 'assets/images/big-language-solutions.png';

const ErrorPage = () => {
    return (
        <div
            data-testid="ErrorPage"
            className="flex items-center justify-center min-h-screen bg-white">
            <div className="flex flex-col">
                <div className="flex flex-col items-center justify-center">
                    <img
                        src={organizationLogo}
                        alt="organization logo"
                        className="max-w-80 ml-8"
                        srcSet=""
                    />
                    <div className="text-primary font-bold text-9xl">404</div>

                    <div className="font-bold text-3xl xl:text-5xl lg:text-5xl md:text-5xl mt-10">
                        This page does not exist
                    </div>

                    <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-1xl mt-8">
                        Try searching again, or return home to start from the beginning.
                    </div>
                    <Link
                        to="/"
                        className="mt-6 inline-block rounded bg-primary px-5 py-3 text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring">
                        Go Back Home
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default ErrorPage;
