import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import customerProcessIcon from 'assets/svgs/customer-processes.svg';
import customerWorkflowIcon from 'assets/svgs/customer-workflow.svg';
import NestedLayout from 'components/ui/layout/nestedLayout/nestedLayout';
import { PATHS } from 'constants/routes.constant';
import { extractPathParam } from 'core/utils/utils';

export default function ProcessesLayout(): JSX.Element {
    const { t } = useTranslation();
    const location = useLocation();
    const customerId = extractPathParam(location.pathname, '@id');
    const customerName = extractPathParam(location.pathname, '@name') ?? '';
    const basePath = `/${PATHS.CUSTOMER}/@id${customerId}/@name${customerName}`;

    const processLayoutTabs = [
        {
            menu: t('CUSTOMER.PROCESSES'),
            id: 1,
            path: '',
            iconPath: customerProcessIcon,
            exactPath: `${basePath}`
        },
        {
            menu: t('CUSTOMER.WORKFLOWS'),
            id: 2,
            path: PATHS.WORKFLOWS,
            iconPath: customerWorkflowIcon,
            exactPath: `${basePath}/${PATHS.WORKFLOWS}`
        }
    ];
    return <NestedLayout tabs={processLayoutTabs} />;
}
