export const PATHS: { [key: string]: string } = {
    COMPANY_DETAILS: 'company-details',
    HOT_FODER_SETUP: 'hot-foders-setup',
    PII_REDACTION: 'pii-redaction',
    PII_REDACTION_STATUS: 'pii-redaction-status',
    MANAGE_FILE_SHARE: 'manage-file-share',
    LOGIN: 'login',

    // common
    CREATE: 'create',
    EDIT: 'edit',
    VIEW: 'view',

    CUSTOMER: 'customer',
    USERS: 'users',
    TRANSLATION_BATCH_TRACKING: 'translation-batch-tracking',
    BATCH: 'batch',
    ADMIN: 'admin',
    ADMIN_MANAGE_FILE_SHARE: 'managefileshare',
    MANAGE_HOT_FOLDERS: 'managehotfolders',
    MANAGE_WORKFLOW: 'manageworkflow',
    PROCESSES: 'processes',
    WORKFLOWS: 'workflows'
};
