import { useNavigate } from 'react-router-dom';

import logo from 'assets/svgs/unauthorized-403.svg';
import { getHeaderMenu } from 'components/ui/layout/headerMenu/headerMenu.constant';
import AuthStore from 'store/authStore/authStore';

export default function UnauthorizedPage() {
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.clear();
        AuthStore.logout();
        navigate('/auth/login', { replace: true });
    };

    return (
        <div
            data-testid="ErrorPage"
            className="flex items-center justify-center min-h-screen bg-white py-48">
            <div className="flex flex-col">
                <div className="flex flex-col items-center">
                    <img src={logo} alt="organization logo" className="max-w-80" srcSet="" />

                    <div className="font-bold text-3xl xl:text-3xl lg:text-3xl md:text-3xl mt-10">
                        You don&apos;t have permission to access this page
                    </div>

                    <div className="text-gray-400 md:text-xl lg:text-1xl mt-8">
                        Try again, or return home to start from the beginning. Or try again using
                        the different account.
                    </div>
                    <div className="mt-6 flex items-center justify-center">
                        <button
                            onClick={() =>
                                getHeaderMenu()?.length > 0
                                    ? navigate(`/${getHeaderMenu()?.[0]?.path}`, { replace: true })
                                    : handleLogout()
                            }
                            className="inline-block rounded px-5 py-3 text-sm font-medium focus:outline-none focus:ring border">
                            Go Back Home
                        </button>
                        <div className="px-4">Or</div>
                        <button
                            onClick={() => handleLogout()}
                            className="inline-block rounded bg-primary px-5 py-3 text-sm font-medium text-white hover:bg-primary focus:outline-none focus:ring">
                            Switch to new account
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
