import { Subject } from 'rxjs';

import { LOCAL_STORAGE_KEYS } from 'constants/site.constant';

const subject = new Subject<any>();

export interface UserInfoModal {
    userInfo: UserDetailsModal;
    authToken: string;
    expiresOn: number;
    isAuthenticated: boolean;
}

export interface UserDetailsModal {
    uid: string;
    firstName: string;
    lastName: string;
    role: string[];
    permissions: UserPermissions;
}

interface UserPermissions {
    [key: string]: string[];
}

const isTokenExist = localStorage.getItem(LOCAL_STORAGE_KEYS.JWT_TOKEN);

const initialState: UserInfoModal = {
    userInfo: {
        permissions: {},
        uid: '',
        firstName: '',
        lastName: '',
        role: []
    },
    expiresOn: 0,
    authToken: '',
    isAuthenticated: false
};

let state: UserInfoModal = {
    ...initialState,
    authToken: isTokenExist ?? '',
    isAuthenticated: !!isTokenExist
};

const AuthStore = {
    init() {
        state = { ...initialState };
        subject.next(state);
    },
    subscribe: (setState: (value: UserInfoModal) => void) => subject.subscribe(setState),
    setLogin(authToken: string) {
        const userInfo: UserInfoModal = {
            isAuthenticated: true,
            expiresOn: 0,
            userInfo: {
                permissions: {},
                uid: '',
                firstName: '',
                lastName: '',
                role: []
            },
            authToken: authToken ?? ''
        };
        localStorage.setItem(LOCAL_STORAGE_KEYS.JWT_TOKEN, authToken ?? '');
        state = {
            ...userInfo
        };
        subject.next(state);
    },
    setUserInfo(userInfo: UserDetailsModal) {
        state = { ...state, userInfo };
        subject.next(state);
    },
    isAuthenticated() {
        return state.isAuthenticated;
    },
    hasPermission(...permissionsToCheck: string[]) {
        return permissionsToCheck.every((permissionToCheck) => {
            // Loop through each key in the user's permissions
            return Object.keys(state.userInfo.permissions).some((key) => {
                const userPermissionsArray = state.userInfo.permissions[key];
                // Check if the permission array for the current key includes the permissionToCheck
                return (
                    Array.isArray(userPermissionsArray) &&
                    userPermissionsArray.includes(permissionToCheck)
                );
            });
        });
    },
    logout() {
        localStorage.clear();
        state = initialState;
        subject.next(state);
    },
    getUserInfo() {
        return state;
    },
    getInitialState() {
        return initialState;
    }
};

export default AuthStore;
