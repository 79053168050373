// Define header menu permissions with category keys
export const headerMenuPermissions = {
    ManageCustomer: 'ManageCustomer',
    ManageProcess: 'ManageProcess',
    ProcessDashboard: 'ProcessDashboard',
    ManageUser: 'ManageUser',
    TranslationBatchTracking: 'TranslationBatchTracking',
    ManageAdmin: 'ManageAdmin',
    ManageFileshare: 'ManageFileshare',
    ManageHotfolders: 'ManageHotfolders',
    ManageWorkflow: 'ManageWorkflow'
};

export const permissions = {
    ManageCustomer: {
        CAN_GET_CUSTOMERS: 'CAN_GET_CUSTOMERS',
        CAN_GET_CUSTOMER: 'CAN_GET_CUSTOMER',
        CAN_CREATE_CUSTOMER: 'CAN_CREATE_CUSTOMER',
        CAN_UPDATE_CUSTOMER: 'CAN_UPDATE_CUSTOMER',
        CAN_DELETE_CUSTOMER: 'CAN_DELETE_CUSTOMER'
    },
    ManageProcess: {
        CAN_GET_PROCESSES: 'CAN_GET_PROCESSES',
        CAN_GET_PROCESS: 'CAN_GET_PROCESS',
        CAN_CREATE_PROCESS: 'CAN_CREATE_PROCESS',
        CAN_UPDATE_PROCESS: 'CAN_UPDATE_PROCESS',
        CAN_DELETE_PROCESS: 'CAN_DELETE_PROCESS'
    },
    ProcessDashboard: {
        CAN_GET_PROCESS_DASHBOARD_LIST: 'CAN_GET_PROCESS_DASHBOARD_LIST'
    },
    ManageUser: {
        CAN_GET_USERS: 'CAN_GET_USERS'
    },
    TranslationBatchTracking: {
        CAN_GET_TRANSLATION_BATCH_TRACKING: 'CAN_GET_TRANSLATION_BATCH_TRACKING'
    },
    ManageAdmin: {
        CAN_VIEW_ADMIN_LAYOUT: 'CAN_VIEW_ADMIN_LAYOUT'
    },
    ManageFileshare: {
        CAN_GET_FILE_SHARES: 'CAN_GET_FILE_SHARES',
        CAN_GET_FILE_SHARE: 'CAN_GET_FILE_SHARE',
        CAN_CREATE_FILE_SHARE: 'CAN_CREATE_FILE_SHARE',
        CAN_UPDATE_FILE_SHARE: 'CAN_UPDATE_FILE_SHARE',
        CAN_DELETE_FILE_SHARE: 'CAN_DELETE_FILE_SHARE'
    },
    ManageHotfolders: {
        CAN_GET_HOT_FOLDERS: 'CAN_GET_HOT_FOLDERS',
        CAN_GET_HOT_FOLDER: 'CAN_GET_HOT_FOLDER',
        CAN_CREATE_HOT_FOLDERS: 'CAN_CREATE_HOT_FOLDERS',
        CAN_UPDATE_HOT_FOLDERS: 'CAN_UPDATE_HOT_FOLDERS',
        CAN_DELETE_HOT_FOLDERS: 'CAN_DELETE_HOT_FOLDERS'
    },
    ManageWorkflow: {
        CAN_GET_WORKFLOWS: 'CAN_GET_WORKFLOWS',
        CAN_GET_WORKFLOW: 'CAN_GET_WORKFLOW',
        CAN_CREATE_WORKFLOW: 'CAN_CREATE_WORKFLOW',
        CAN_UPDATE_WORKFLOW: 'CAN_UPDATE_WORKFLOW',
        CAN_DELETE_WORKFLOW: 'CAN_DELETE_WORKFLOW'
    }
};
