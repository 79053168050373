import { logger } from 'core/utils/utils';

export interface EnvConfig {
    apiBaseUrl: string;
    appEnv: string;
    buildVersion: string;
    [key: string]: string;
}

let envConfig: EnvConfig;

/**
 * Loads the environment configuration from env variables or fallback to config.json.
 */
export const loadConfig = async (): Promise<void> => {
    try {
        const response: Response = await fetch('/config.json');

        // Cast the result of response.json() to the EnvConfig type
        const config = (await response.json()) as unknown as EnvConfig;

        envConfig = {
            apiBaseUrl:
                (process.env?.REACT_APP_API_BASE_URL ?? config?.REACT_APP_API_BASE_URL) || '',
            appEnv: (process.env?.REACT_APP_ENV ?? config?.REACT_APP_ENV) || '',
            buildVersion:
                (process.env?.REACT_APP_BUILD_VERSION ?? config?.REACT_APP_BUILD_VERSION) || ''
        };
    } catch (error) {
        logger(['Error loading configuration:', error]);
        throw new Error('Failed to load configuration');
    }
};

/**
 * Returns the environment configuration.
 * @returns {EnvConfig} The environment configuration object.
 * @throws {Error} If the config has not been loaded yet.
 */
export const getConfig = async (): Promise<EnvConfig> => {
    if (!envConfig) {
        try {
            await loadConfig();
        } catch (error) {
            logger(['Error getting configuration:', error]);
            throw new Error('Failed to get configuration');
        }
    }
    return envConfig;
};
