export const renderTitle = (title: string) => {
    return (
        <div className="p-1.5 pt-1 pl-5 max-h-[39px] shadow-custom text-[18px] leading-[32px] font-medium bg-white">
            {title}
        </div>
    );
};

export const handleFormSubmission = async (id: string) => {
    (document.querySelector(`#${id}`) as HTMLButtonElement)?.click();
    await new Promise((r) => setTimeout(r, 100));
};
