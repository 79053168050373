import { RouteObject } from 'react-router-dom';

import { PATHS } from 'constants/routes.constant';

export const customerRoutes: RouteObject[] = [
    {
        index: true,
        async lazy() {
            const Customer = await import('./customer');
            return {
                Component: Customer.default
            };
        }
    },
    {
        path: PATHS.CUSTOMER,
        async lazy() {
            const Customer = await import('./customer');
            return {
                Component: Customer.default
            };
        }
    }
];
