import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { getHeaderMenu } from './headerMenu.constant';
import organizationLogo from 'assets/images/big-language-solutions.png';
import adminMenuIcon from 'assets/svgs/admin_menu_icon.svg';
import customerMenuIcon from 'assets/svgs/customer_menu_icon.svg';
import navbar from 'assets/svgs/hamburger.svg';
import navbarSelectIcon from 'assets/svgs/hamburger_1.svg';
import tbdMenuIcon from 'assets/svgs/tbd_menu_icon.svg';
import userMenuIcon from 'assets/svgs/user_menu_icon.svg';
import CustomSidebar from 'components/ui/sidebar/customSidebar';
import AuthStore from 'store/authStore/authStore';

export interface MenuItem {
    menu: string;
    id: number;
    permission?: string[];
    path: string;
    iconPath: string;
    submenu?: SubmenuItem[];
}
interface SubmenuItem {
    title: string;
    id: number;
    permission: string[];
    path: string;
}

export default function HeaderMenu() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const headerMenuData: MenuItem[] = getHeaderMenu();
    const [visible, setVisible] = useState<boolean>(false);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split('/');
    const isActive = (path: string) => splitLocation[1].toLowerCase() === path.toLowerCase();

    const isSubmenuActive = (submenu: SubmenuItem[]) => {
        return submenu.some((submenuItem: SubmenuItem) => isActive(submenuItem.path));
    };
    const icons = {
        'assets/svgs/admin_menu_icon.svg': adminMenuIcon,
        'assets/svgs/customer_menu_icon.svg': customerMenuIcon,
        'assets/svgs/user_menu_icon.svg': userMenuIcon,
        'assets/svgs/tbd_menu_icon.svg': tbdMenuIcon
    };
    const userMenu: MenuItem[] = headerMenuData.map((item) => ({
        ...item,
        iconPath: (icons[item.iconPath] as string) || item.iconPath
    }));

    const handleLogout = () => {
        localStorage.clear();
        AuthStore.logout();
        navigate('/auth/login', { replace: true });
    };

    return (
        <div data-testid="HeaderMenu">
            <div className="w-full border-t-[3px] border-b-1.3 border-b-lavenderMist border-secondary h-[52px] flex items-center px-3 relative z-10">
                <div className="h-full py-2 pl-3 flex items-center">
                    <button
                        className="mr-2 btn-role"
                        onClick={() => setVisible(true)}
                        data-testid="navbar-icon"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') setVisible(true);
                        }}>
                        <img src={navbar} alt="Navbar Icon" />
                    </button>
                    <img
                        src={organizationLogo}
                        className="h-[44px] w-[121px]"
                        alt="Organization logo"
                        data-testid="organization-logo"
                    />
                </div>
                <CustomSidebar
                    id="sidebar-header"
                    visible={visible}
                    position="left"
                    showFooter={false}
                    isFormIo={false}
                    onHide={() => setVisible(false)}
                    pt={{
                        root: { className: 'w-3/4 sm:w-1/3 md:w-1/3 lg:w-1/4 xl:w-1/5 relative' },
                        header: {
                            className: 'w-10 p-2 absolute right-0'
                        },
                        icons: {
                            className: 'hidden'
                        },
                        closeButtonIcon: {
                            className: 'transition-none'
                        },
                        content: {
                            className: 'pt-4 p-5'
                        }
                    }}>
                    <button
                        className="mb-6 mt-2 btn-role"
                        onClick={() => setVisible(false)}
                        data-testid="sidebar-nav-icon"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') setVisible(false);
                        }}>
                        <img src={navbarSelectIcon} alt="Navbar Icon" />
                    </button>
                    <ul>
                        {userMenu.map((item) =>
                            item.submenu ? (
                                <div key={item.id}>
                                    <li
                                        data-testid={'p-' + item.menu}
                                        className={`cursor-pointer p-2 pb-1 font-medium text-lg ${isSubmenuActive(item.submenu) ? 'border-l-4 border-l-secondary text-secondary' : ''}`}
                                        key={`p-${item.id}`}>
                                        {t(`${item.menu}`)}
                                    </li>
                                    {item.submenu.map((submenuItem) => (
                                        <Link
                                            data-testid={'s-' + item.menu}
                                            to={submenuItem.path}
                                            key={`s-${submenuItem.id}`}
                                            onClick={() => setVisible(false)}
                                            className={`${isActive(submenuItem.path) ? 'text-secondary' : ''}`}>
                                            <li className="ml-5 p-2 font-medium text-sm">
                                                {t(`${submenuItem.title}`)}
                                            </li>
                                        </Link>
                                    ))}
                                </div>
                            ) : (
                                <Link
                                    data-testid={'p-' + item.menu}
                                    to={item.path}
                                    key={`p-${item.id}`}
                                    onClick={() => setVisible(false)}
                                    className={`flex cursor-pointer py-c-9 ${isActive(item.path) ? 'text-primary-50' : ''}`}>
                                    <img
                                        src={item.iconPath}
                                        className="mr-3"
                                        alt="Navbar Icon"
                                        data-testid={'navbar-icon-' + item.menu}
                                    />
                                    <li className="font-medium text-sm mt-0.5">
                                        {t(`${item.menu}`)}
                                    </li>
                                </Link>
                            )
                        )}
                    </ul>
                </CustomSidebar>
                <div className="ml-auto h-full flex items-center">
                    <button
                        data-testid="PROFILE.LOGOUT"
                        className="py-1 px-0 lg:hover:font-bold cursor-pointer mr-3 hover:scale-105 transform transition duration-200"
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleLogout();
                            }
                        }}
                        onClick={() => {
                            handleLogout();
                        }}>
                        <div
                            data-testid="logout"
                            className="font-medium text-secondary flex items-center space-x-2">
                            <i className="pi pi-sign-out w-4" />
                            <span className="mb-1">{t('PROFILE.LOGOUT')}</span>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}
