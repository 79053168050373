import { RouteObject } from 'react-router-dom';

import { PATHS } from 'constants/routes.constant';

export const translationBatchTrackingRoutes: RouteObject[] = [
    {
        path: PATHS.TRANSLATION_BATCH_TRACKING,
        async lazy() {
            const TranslationBatchTracking = await import('./translationBatchTracking');
            return {
                Component: TranslationBatchTracking.default
            };
        }
    }
];
