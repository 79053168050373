/* eslint-disable unicorn/prefer-string-replace-all */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

import { END_POINT } from 'constants/endPoint';
import { LogLevel } from 'constants/site.constant';
import { EnvConfig, getConfig } from 'core/configs/configHelper';
import { logger, toggleLoadingIndicator } from 'core/utils/utils';
import AuthStore, { UserInfoModal } from 'store/authStore/authStore';
import NotificationStore, {
    INotificationModel,
    ToastType
} from 'store/notificationStore/notificationStore';

export enum EHttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

class HttpService {
    private http: AxiosInstance | null = null;
    public baseURL: string | null = null;
    private initialized: boolean = false;

    // Ensure the service is initialized before making a request (Lazy initialization)
    private async ready(): Promise<void> {
        if (!this.initialized) {
            await this.init(); // Initialize only on first use
        }
    }

    // Initialize the HttpService
    private async init(): Promise<void> {
        try {
            const config = await getConfig();
            this.baseURL = (config as unknown as EnvConfig)?.apiBaseUrl;
            if (this.baseURL) {
                this.http = axios.create({
                    baseURL: this.baseURL,
                    withCredentials: false,
                    headers: this.setupHeaders()
                });
                this.initialized = true;
            } else {
                throw new Error('Base URL not found in configuration');
            }
        } catch (error) {
            logger(['Failed to initialize HttpService:', error]);
            throw error;
        }
    }

    // Get authorization token for requests
    private get getAuthorization() {
        const userInfo: UserInfoModal = AuthStore.getUserInfo();
        const accessToken = userInfo?.authToken || '';
        return accessToken ? { Authorization: `Bearer ${accessToken}` } : {};
    }

    // Set up request headers
    private setupHeaders(hasAttachment = false) {
        return {
            'Content-Type': hasAttachment ? 'multipart/form-data' : 'application/json',
            ...this.getAuthorization
        };
    }

    // Update JSON data with dynamic values like BASE_URL and Authorization Token
    public async updateJsonDataWithDynamicValues(jsonData: any): Promise<object> {
        // Ensure that the service is initialized before proceeding
        await this.ready();

        // Replace BASE_URL placeholder with actual base URL
        const { apiBaseUrl } = await getConfig();
        const stringifiedJson = JSON.stringify(jsonData);

        // Replace REACT_APP_API_BASE_URL with the correct baseURL
        let updatedJson = stringifiedJson.replace(
            /REACT_APP_API_BASE_URL/g,
            (this.baseURL ?? apiBaseUrl) || ''
        );

        // Get the authorization token
        const authorization = this.getAuthorization;

        if (authorization?.Authorization) {
            // Replace ACCESS_TOKEN_HERE placeholder with the actual access token
            updatedJson = updatedJson.replace(/ACCESS_TOKEN_HERE/g, authorization.Authorization);
        }

        return JSON.parse(updatedJson) as unknown as object;
    }

    // Handle HTTP requests
    private async request<T>(
        method: EHttpMethod,
        url: string,
        options: AxiosRequestConfig,
        showToast?: boolean,
        showLoader?: boolean,
        baseUrl?: string
    ): Promise<T> {
        await this.ready(); // Ensure the HTTP client is initialized

        // Call the function to show the loader
        if (!url.includes(END_POINT.TRANSLATIONBATCH.FILE_NAMES_LIST) && (showLoader ?? true)) {
            toggleLoadingIndicator(true);
        }

        try {
            const response: AxiosResponse<T> = await this.http!.request<T>({
                method,
                url,
                baseURL: baseUrl,
                ...options
            });
            // Call the function to hide the loader
            toggleLoadingIndicator(false);
            return response.data;
        } catch (error) {
            // Call the function to hide the loader in case of an error
            toggleLoadingIndicator(false);
            return this.normalizeError(error as AxiosError, showToast ?? true);
        }
    }

    // Perform GET request
    public async get<T>(
        url: string,
        params?: AxiosRequestConfig,
        hasAttachment?: boolean,
        showToast?: boolean,
        showLoader?: boolean,
        baseUrl?: string
    ): Promise<T> {
        return this.request<T>(
            EHttpMethod.GET,
            url,
            {
                params,
                baseURL: baseUrl,
                headers: this.setupHeaders(hasAttachment ?? false)
            },
            showToast,
            showLoader
        );
    }

    // Perform POST request
    public async post<T, P>(
        url: string,
        payload: P,
        params?: AxiosRequestConfig,
        hasAttachment?: boolean,
        showToast?: boolean,
        showLoader?: boolean,
        baseUrl?: string
    ): Promise<T> {
        return this.request<T>(
            EHttpMethod.POST,
            url,
            {
                params,
                data: payload,
                baseURL: baseUrl,
                headers: this.setupHeaders(hasAttachment ?? false)
            },
            showToast ?? true,
            showLoader
        );
    }

    // Perform UPDATE request
    public async update<T, P>(
        url: string,
        payload: P,
        params?: AxiosRequestConfig,
        hasAttachment?: boolean,
        showToast?: boolean,
        showLoader?: boolean,
        baseUrl?: string
    ): Promise<T> {
        return this.request<T>(
            EHttpMethod.PUT,
            url,
            {
                params,
                data: payload,
                baseURL: baseUrl,
                headers: this.setupHeaders(hasAttachment ?? false)
            },
            showToast ?? true,
            showLoader
        );
    }

    // Perform DELETE request
    public async delete<T>(
        url: string,
        params?: AxiosRequestConfig,
        hasAttachment = false
    ): Promise<T> {
        return this.request<T>(EHttpMethod.DELETE, url, {
            params,
            headers: this.setupHeaders(hasAttachment)
        });
    }

    // Normalize errors
    private normalizeError(error: AxiosError, showToast?: boolean) {
        if (error.response?.status === 401) {
            localStorage.clear();
            window.location.href = '/auth/login?from=' + window.location.pathname;
        }
        if (error.response?.status === 403) {
            localStorage.clear();
            window.location.href = '/access-denied';
        }
        setTimeout(() => {
            toggleLoadingIndicator(false);
        }, 500);
        const toast: INotificationModel = {
            message: error?.message || 'An unknown error occurred.',
            type: ToastType.ERROR
        };
        !!showToast && NotificationStore.showAlert(toast);
        logger([], LogLevel.CLEAR);
        return Promise.reject(error);
    }
}

export { HttpService as default };
