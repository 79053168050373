import { ILoginResponse } from './login.types';
import { API_VERSION, END_POINT } from 'constants/endPoint';
import HttpService from 'core/interceptor/api.handler';
import { logger } from 'core/utils/utils';
import { ApiResponseWithoutPagination } from 'shared/types/api';
import AuthStore, { UserDetailsModal } from 'store/authStore/authStore';

const _httpService = new HttpService();

export const loginApi = async <T>(
    requestBody: T
): Promise<ApiResponseWithoutPagination<ILoginResponse>> => {
    const apiUrl = `${API_VERSION['V1.0']}/${END_POINT.AUTH.LOGIN}/`;
    try {
        const response: ApiResponseWithoutPagination<ILoginResponse> = await _httpService.post(
            apiUrl,
            requestBody,
            {},
            false,
            false
        );
        return response;
    } catch (error) {
        logger(['Error data:', error]);
        throw error;
    }
};

export const updateUserInformation = (token: string) => {
    AuthStore.setLogin(token);
};

export const getMe = async () => {
    const apiUrl = `${API_VERSION['V1.0']}/${END_POINT.USER.ME}?include=permissions`;
    try {
        const response: ApiResponseWithoutPagination<UserDetailsModal> = await _httpService.get(
            apiUrl,
            {},
            false,
            true,
            false
        );

        return response;
    } catch (error) {
        logger(['Error data:', error]);
        throw error;
    }
};

export const setUserInformation = (userInfo: UserDetailsModal) => {
    AuthStore.setUserInfo(userInfo);
};
